<template>
  <div class="views-page is-flex is-flex-direction-column px-4 py-4">
    <div
      class="info-line"
      :class="GetMessagesData.main_color"
    ></div>
    <div class="column p-0 tag-parent-mobile is-flex is-flex-wrap-wrap">
      <span
        class="tag-mobile is-size-6 has-text-weight-medium tag px-4 py-4 mr-3 mb-2"
        v-for="(Msg,id) in GetMessagesData.cards"
        :key="Msg.id"
        :class="Msg.color"
      >{{ Msg.name }}</span>
    </div>
    <div
      id="chartdiv"
      ref="chartdiv"
      style="z-index: 10"
    ></div>
    <b-loading
      :is-full-page="false"
      v-model="isLoading"
      :can-cancel="false"
    />
    <div>
      <div style="max-width: 500px">
        <b-field label="Выберите тип">
          <!-- @input="SendData" -->
          <b-select
            placeholder="Тип"
            v-model="selectedType"
            style="min-width: 20rem"
            expanded
            @input="onSelectType"
          >
            <option
              v-for="type in types"
              :value="type.value"
              :key="type.value"
            >
              {{ type.name }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Выберите канал">
          <!-- @input="SendData" -->
          <b-select
            placeholder="Название"
            v-model="selected_channel"
            :loading="typesIsLoading"
            :disabled="!selectedType"
            style="min-width: 20rem"
            expanded
            @input="onSelectChange"
          >
            <option
              v-for="(option, idx) in channels"
              :value="option"
              :key="idx"
            >
              {{ option }}
            </option>
          </b-select>
        </b-field>
        <b-field label="Выберите названия линии">
          <b-dropdown
            ref="multipleDropdown"
            :disabled="typesIsLoading || !names[selected_channel] || !selectedType"
            aria-role="list"
            scrollable
            max-height="200"
            expanded
            class="is-fullwidth"
          >
            <template #trigger>
              <b-button
                type="is-secondary"
                icon-pack="fas"
                outlined
                class="btn-icon is-fullwidth is-flex is-justify-content-space-between"
                icon-right="fa-chevron-down"
              >{{ selectedNames.length ? selectedNames.join(', ') : 'Выбрать' }}</b-button>
<!--              <b-select-->
<!--                :placeholder="selectedNames.length ? selectedNames.join(', ') : 'Выбрать'"-->
<!--                class="select-names"-->
<!--                :open-on-click="false"-->
<!--              >-->
<!--              </b-select>-->
            </template>
            <b-dropdown-item v-if="names[selected_channel] && names[selected_channel].length" custom style="cursor: pointer" aria-role="listitem">
              <b-checkbox
                :value="selectedNames.length === names[selected_channel].length"
                :indeterminate="selectedNames.length !== names[selected_channel].length && selectedNames.length !== 0"
                @input.native="selectAllNames"
              >Выбрать все</b-checkbox>
<!--              <span @click="selectAllNames">{{ selectedNames.length === names[selected_channel].length ? 'Очистить' : 'Выбрать все' }}</span>-->
            </b-dropdown-item>
            <b-dropdown-item v-for="name in names[selected_channel] || []" custom :key="name" :value="name" aria-role="listitem">
              <b-checkbox v-model="selectedNames" :native-value="name" @input.native="$refs.multipleDropdown.selectItem(name)">{{ name }}</b-checkbox>
<!--              <span>{{ name }}</span>-->
            </b-dropdown-item>
          </b-dropdown>
        </b-field>
        <div class="is-flex is-flex-direction-column mb-3">
          <b-field label="Период отображения данных:">
            <b-datepicker
              v-model="dates"
              range
              :disabled="!selectedType"
              :first-day-of-week="1"
              icon="calendar-days"
              icon-pack="fas"
              placeholder="Выберите даты"
              position="is-top-right"
              class="trends-spec__datepicker"
            >
              <div class="columns is-multiline is-gapless">
                <div class="column is-half" style="padding: 2px !important;">
                  <b-button
                    class="column is-half"
                    style="height: auto; width: 100%"
                    label="Сегодня"
                    type="is-info"
                    size="is-small"
                    @click="handleDatesChange(1, 'day')"
                  />
                </div>
                <div class="column is-half" style="padding: 2px !important;">
                  <b-button
                    class="column is-half"
                    style="height: auto; width: 100%"
                    label="Неделя"
                    type="is-info"
                    size="is-small"
                    @click="handleDatesChange(7 , 'day')"
                  />
                </div>
                <div class="column is-half" style="padding: 2px !important;">
                  <b-button
                    class="column is-half"
                    style="height: auto; width: 100%"
                    label="Месяц"
                    type="is-info"
                    size="is-small"
                    @click="handleDatesChange(1, 'month')"
                  />
                </div>
                <div class="column is-half" style="padding: 2px !important;">
                  <b-button
                    class="column is-half"
                    style="height: auto; width: 100%"
                    label="Очистить"
                    size="is-small"
                    type="is-danger"
                    outlined
                    @click="dates = []"
                  />
                </div>
              </div>
            </b-datepicker>
          </b-field>
          <b-button
            class="mt-3"
            type="is-info"
            :loading="isLoading"
            :disabled="!selectedType || !selected_channel || selectedNames.length === 0"
            label="Применить"
            @click="fetchByDate"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4lang_ru_RU from "@amcharts/amcharts4/lang/ru_RU";
import dayjs from "dayjs"

const COLOR_LIST = [
  "#BC8034",
  "#6DD400",
  "#0091FF",
  "#6236FF",
  "#ff00c8",
  "#9B9B9B",
  "#44D7B6",
  "#0D7B80",
  "#2c3e50",
  "#27ae60",
  "#9b59b6",
  "#3498db",
  "#1abc9c",
  "#d35400",
  "#CA2E55",
  "#7B9E89",
  "#7389AE",
  "#93E1D8",
  "#56351E",
  "#8C7A6B"
];

export default {
  props: ["device_id"],
  data: () => ({
    chart: null,
    legends: null,
    names: [],
    chartNames: [],
    date: {},
    dates: [],
    channels: [],
    ListSelect: {},
    isLoading: false,
    spectraData: null,
    selected_channel: null,
    selectedNames: [],
    selected: null,
    isShown: false,
    currentData: null,
    types: [
      { name: 'Огибающая', value: 'envelope' },
      { name: 'Ускорение', value: 'acceleration' },
      { name: 'Скорость', value: 'velocity' },
    ],
    typesIsLoading: false,
    selectedType: null,
    alarmAndWarnings: [],
    speed: [],
    valueAxis: [],
    valueAxisSpeed: []
    // trendColor: ["#FAA700", "#6DD400", "#0091FF", "#6236FF", "#B620E0", "#9B9B9B", "#44D7B6", "#0D7B80", "#22A699", "#F2BE22", "#F29727", "#47A992", "#FFE194"],
  }),
  computed: mapGetters(["GetMessagesData"]),
  watch: {
    dates(newValue, oldValue) {
      if(newValue && newValue !== oldValue) {
        this.date = {
          from: dayjs(newValue[0] || new Date()).valueOf(),
          to: dayjs(newValue[1] || new Date()).valueOf()
        }
        if(oldValue.length > 0) this.fetchByDate()
      }
    }
  },
  mounted() {
    // this.device_id = this.$router.history.current.params.id.replace(/[^0-9]/g,"");
    // this.fetchSpecTrendsSelect(this.device_id);

    // this.device_id = this.$router.history.current.params.id.replace(/[^0-9]/g,"");
    this.FetchMessages(this.device_id);
    //create an instance of amCharts
    this.chart = am4core.create(this.$refs.chartdiv, am4charts.XYChart);

    //language
    this.chart.language.locale = am4lang_ru_RU;

    // Add data
    // this.chart.data = this.spectraData;

    // Create axes date X
    var dateAxis = this.chart.xAxes.push(new am4charts.DateAxis());
    dateAxis.renderer.grid.template.location = 0;
    dateAxis.renderer.minGridDistance = 100;


    // First value axis Y
    var valueAxis = this.chart.yAxes.push(new am4charts.ValueAxis());
    valueAxis.title.text = "мм/с";
    valueAxis.renderer.line.strokeOpacity = 1;
    valueAxis.renderer.line.strokeWidth = 2;
    valueAxis.title.rotation = 90;
    valueAxis.title.valign = "top";
    valueAxis.title.fontWeight = 600;
    valueAxis.keepSelection = true
    this.valueAxis = valueAxis


    // Second value axis
    var valueAxisSpeed = this.chart.yAxes.push(new am4charts.ValueAxis());
    valueAxisSpeed.renderer.line.strokeOpacity = 1;
    valueAxisSpeed.renderer.line.strokeWidth = 2;
    valueAxisSpeed.renderer.opposite = true;
    valueAxisSpeed.title.text = "об/мин";
    valueAxisSpeed.title.rotation = 90;
    valueAxisSpeed.title.valign = "top";
    valueAxisSpeed.title.fontWeight = 600;
    this.valueAxisSpeed = valueAxisSpeed

    //create legend of series
    this.chart.legend = new am4charts.Legend();

    // this.chart.customLegend = document.getElementById('legend')

    //create event to cursor
    this.chart.cursor = new am4charts.XYCursor();

    this.handleDatesChange(1, 'day')

    // this.getTypes()
  },
  beforeDestroy() {
    this.chart.dispose()
  },
  methods: {
    ...mapMutations(["ChangeNavName"]),
    ...mapActions(["FetchMessages"]),
    selectAllNames() {
      if(!this.names[this.selected_channel]) return
      this.selectedNames = this.names[this.selected_channel].length !== this.selectedNames.length ? this.names[this.selected_channel] : []
    },
    handleDatesChange(value, unit) {
      const from = dayjs().subtract(value, unit)
      const to = dayjs()
      this.dates = [new Date(from.format()), new Date(to.format())]
    },
    onSelectChange() {
      this.selectedNames = []
      // if(this.dates.length === 2) this.fetchByDate()
    },
    async onSelectType() {
      this.typesIsLoading = true
      this.selectedNames = []
      try {
        const { data } = await this.$axios.get(`user/agents/${this.device_id}/trend-specter-types`, {
          params: { type: this.selectedType }
        })
        this.channels = data?.data?.channel || []
        this.names = data?.data?.grouped_names || {}
        // if(this.channels[0]) {
        //   this.selected_channel = this.channels[0]
        //   this.handleDatesChange(1, 'day')
        // }

        if(this.valueAxis) {
          if(this.selectedType === 'velocity') this.valueAxis.title.text = 'мм/с'
          else this.valueAxis.title.text = 'м/с2'
        }
      } catch (e) {
        console.error(e)
        throw e
      } finally {
        this.typesIsLoading = false
      }
    },
    createSeries(field, name, zindex, color, formatter, valueAxis) {
      const series = this.chart.series.push(new am4charts.LineSeries());
      series.dataFields.valueY = field;
      series.dataFields.dateX = "time";
      series.name = name;
      // series.tooltipText = "{name} {dateX}: [b]{valueY}[/]";
      series.tooltip.zIndex = 50
      series.strokeWidth = 2;
      if(color) {
        series.stroke = am4core.color(color);
        series.tooltip.fillcolor = color;
      }
      if (formatter) {
        series.numberFormatter = new am4core.NumberFormatter();
        series.numberFormatter.numberFormat = formatter;
      }
      series.strokeDasharray = field === "warning" ? "8,4" : "0"
      series.tooltipText = "[{stroke.hex}]●[/]{name}: [bold]{valueY}[/]"
      series.tooltip.getFillFromObject = false
      series.tooltip.background.fill = am4core.color("#fff")
      series.tooltip.label.fill = am4core.color("#000")
      series.zIndex = zindex;
      series.yAxis = valueAxis ?? this.valueAxis;
      return series;
    },
    fillChart() {
      const key = this.chart.series.length;
      for (let i = 0; i < key; i++) {
        this.chart.series.removeIndex(0).dispose();
      }

      for (let j = 0; j < this.chartNames.length; j++) {
        this.createSeries("avg" + (j + 1), this.chartNames[j], j, COLOR_LIST[j], '#.00');
      }

      if(this.alarmAndWarnings.length > 0) {
        this.createSeries('alarm', 'Тревога', 50, '#FF0000')
        this.createSeries('warning', 'Предупреждение', 50, '#FF0000')
      }

      if(this.speed.length > 0)
        this.createSeries('speed', 'Скорость', 1, '#7F00FF', null, this.valueAxisSpeed)

      this.chart.data = this.spectraData;
    },
    async getLimits() {
      try {
        const { data } = await this.$axios.post(`user/agents/${this.device_id}/trend-specter-limits`, {
          channel: this.selected_channel,
          to: this.date.to,
          from: this.date.from,
          type: this.selectedType,
          name: this.selectedNames.length > 0 ? this.selectedNames[0] : this.names[this.selected_channel]?.[0] ?? ''
        })
        this.alarmAndWarnings = data?.data || []
        this.spectraData = [...this.spectraData, ...this.alarmAndWarnings]
      } catch (e) {
        console.error(e)
        throw e
      }
    },
    async getSpeed() {
      try {
        const { data } = await this.$axios.post(`user/agents/${this.device_id}/trend-speed`, {
          to: this.date.to,
          from: this.date.from,
        })
        this.speed = data?.data || []
        // this.spectraData = [...this.speed, ...this.spectraData]
        this.spectraData = (this.spectraData || []).map(elem => {
          return {
            ...elem,
            ...(this.speed || []).find(tElem => tElem.time === elem.time)
          }
        })
      } catch (e) {
        console.error(e)
        throw e
      }
    },
    async fetchByDate() {
      this.isLoading = true
      try {
        const { data } = await this.$axios.post(`user/agents/${this.device_id}/trend-specters`, {
          channel: this.selected_channel,
          to: this.date.to,
          from: this.date.from,
          names: this.selectedNames,
          type: this.selectedType
        })
        this.spectraData = data.data ?? [];
        if(this.names.length ===0) this.names = data.names ?? []
        this.chartNames = data?.names ?? []
        await this.getLimits()
        await this.getSpeed()
        this.fillChart()
      } catch (e) {
        console.error(e)
        throw e
      } finally {
        this.isLoading = false
      }
    },
  },
};
</script>

<style lang="scss">
.highlight {
  background: #61a937;
}

</style>
